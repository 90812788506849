/**
 * ////////////////////  Font Style ////////////////////
 *
 * @format
 */
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Nunito:wght@500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;800;900&display=swap");

body {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // background: #030912 !important;
}
* {
  // font-family: "Poppins", sans-serif !important;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}
button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a {
  text-decoration: none !important;
}
/* ::placeholder {
    color: #6D7580;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: #6D7580;
  }
  
  ::-ms-input-placeholder {
    color: #6D7580;
  } */

/* ////////////////////// SCROLL COLOR ///////////////////////////// */

::-webkit-scrollbar {
  width: 5px;

  overflow: auto;
}

::-webkit-scrollbar-track {
  background: #fff;
  overflow: auto;
}

::-webkit-scrollbar-thumb {
  transform: scale(1.5);
  border-left: 10px #ff32a9ff solid;
}

::-webkit-scrollbar-thumb:hover {
  transform: scale(1.5);
  border-left: 10px #ff32a9ff solid;
}
.btn-s {
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 40px;
  height: 60px;
  color: #fff;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #fff;
  text-align: center;
  font-size: 20px;
  border: none !important;
  font-weight: 600;
  line-height: 1.1;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  font-family: "Poppins", sans-serif !important;

  transition: all 0.3s ease 0s;
  background: url("../public/assets/btn_r_center.png") repeat-x;
  background-size: contain;
  border-radius: 40px;
}
.btn-s span {
  position: relative;
  z-index: 1;
}
.btn-s::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 70px;
  background-size: contain;
}
.btn-s::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 70px;
  background-size: contain;
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
@media (min-width: 1024px) {
  .btn-s:hover {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
}
@media (max-width: 1024px) {
  .btn-s {
    height: 50px;
    font-size: 16px;
    padding: 10px 20px;
  }
  .btn-s::before {
    width: 26px;
    height: 50px;
  }
  .btn-s::after {
    width: 26px;
    height: 50px;
  }
}
@media (max-width: 360px) {
  .btn-s {
    font-size: 14px;
  }
}
.btn-b {
  background: url("../public/assets/btn_b_center.png") repeat-x;
}
/* /////////////////////////////////// TOOGLER ICON COLOR CHANGE COLOR ////////////////////////// */

.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #000;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  background-color: transparent !important;
  padding: 10px !important;
}

.offcanvas {
  backdrop-filter: blur(10px) !important;
  background-color: #00000080 !important;
}

/* ////////////////////////////    HEADER SECTION ////////////////////////// */

.Header-Selection {
  background: transparent !important;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  left: 0;
  z-index: 999;
  .navbar-nav {
    a {
      font-size: 1.5rem !important;
      margin: 0px 20px;
      font-weight: 800;
      font-family: Dancing Script;
      color: #000 !important;
    }
  }
}
.navbar-brand {
  font-size: 30px !important;
  font-weight: 700;
  color: #000 !important;
}

/////////////////////  .HERO SECTION   ///////////////
.sec-bg2 {
  background: linear-gradient(90deg, #e0f1f9 53%, #ffffff67),
    url(../public/assets/bgs1.png) no-repeat top right;
  background-size: contain;
  position: relative;
  min-height: auto;
  padding: 40px 0;
  padding-bottom: 60px;

  @media screen and (max-width: 600px) {
    background-size: cover;
  }
}
.sec-bg2-fixed--height {
  min-height: 1080px;
  width: 100%;
  // display: flex;
  // align-items: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .sec-bg2-fixed--height {
    min-height: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-bg2-fixed--height {
    min-height: auto;
    padding: 60px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-bg2-fixed--height {
    min-height: auto;
    padding: 80px 0;
    padding-bottom: 60px;
  }
}
.hero-section {
  .content {
    position: relative;
    z-index: 10;

    p {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
    }
  }
}
.hero__title {
  text-align: left;
  // margin: 0px 0px 0px calc((100vw - 1300px) / 2);
  font-size: 115px;
}
@media (max-width: 1360px) {
  .hero__title {
    margin: 0px 0px 0px 30px;
    font-size: calc(44px + 81 * ((100vw - 320px) / 1600));
  }
}
@media (max-width: 700px) {
  .hero__title {
    text-align: center;
    margin: 0px 30px 20px 30px;
  }
}

.title {
  color: #fff;
  font-size: 92px;
  font-family: Dancing Script;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
  -webkit-text-stroke: 10px #ff32a9;
  text-align: center;
}
.title::before {
  content: attr(data-text);
  position: absolute;
  left: 0;
  right: 0;
  -webkit-text-stroke: 0px #fff;
}
@media (max-width: 1360px) {
  .title {
    font-size: calc(48px + 52 * ((100vw - 320px) / 1600));
  }
}
@media (max-width: 820px) {
  .title {
    -webkit-text-stroke: 7px #ff32a9;
  }
}
@media (max-width: 480px) {
  .title {
    -webkit-text-stroke: 6px #ff32a9;
  }
}
.title[data-text] {
  text-shadow: 6px 6px 10px rgba(255, 50, 169, 0.5);
}
@media (max-width: 820px) {
  .title[data-text] {
    text-shadow: none;
  }
}
.hero__subtitle {
  color: #3bc6ff;
  font-size: 49px;
  font-family: Dancing Script;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 30px 0px;
}
@media (max-width: 1050px) {
  .hero__subtitle {
    margin: 0px 0px 60px 0px;
  }
}
@media (max-width: 1360px) {
  .hero__subtitle {
    font-size: calc(28px + 27 * ((100vw - 320px) / 1600));
  }
}
@media (max-width: 700px) {
  .hero__subtitle {
    text-align: center;
    padding: 0px 15px;
    margin: 0px 0px 10px 0px;
  }
}

// //////////////  fan-sec /////////////////////
.fan-sec {
  background: linear-gradient(
    180deg,
    rgba(224, 241, 249, 1) 0%,
    rgba(248, 252, 255, 1) 34%,
    rgba(248, 252, 255, 1) 57%
  );

  img {
    margin-top: -60px !important;
  }
  h5 {
    color: #000;
    font-size: 22px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .circle {
    width: 15px;
    height: 15px;
    background: #ff32a9;
    display: inline-block;
    border-radius: 50%;
  }
}
.fans__title {
  margin: 0px 100px 55px 100px;
  font-size: 70px;
}
@media (max-width: 700px) {
  .fans__title {
    margin: 0px 0px 40px 0px;
  }
}
////////////////////////  ABOUT SEREC

.About-section {
}
.img-br {
  border: 2px solid #ff32a9;
}
.title_about {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 810px;
  flex: 0 1 810px;
  color: #000;
  font-size: 20px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  margin-top: 20px;
}
::marker {
  color: #ff32a9;
  font-size: larger;
}

// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               Roadmap
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================

.roadmap .partner-container li {
  margin-top: 10px;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 810px;
  flex: 0 1 810px;
  color: #000;
  font-size: 20px;
  font-family: Nunito;
  font-style: normal;
}
.roadmap .partners {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding-bottom: 70px;
}
.roadmap .partners .partner-container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 100%;
  padding: 50px 50px 0;
  position: relative;
}
.roadmap .gradient-text {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(0deg, #ff32a9 0, #3bc6ff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.roadmap .logo-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.roadmap .logo-container a {
  display: flex;
  flex: 1;
  max-height: 100%;
  max-width: 40%;
}
.roadmap .logos {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: space-around !important;
  align-content: space-around;
}
.hero-2 {
  color: #ff32a9;
}
@media screen and (min-width: 932px) {
  .roadmap .partner-container:not(:last-of-type):after {
    background: linear-gradient(0deg, #ff32a9 0, #3bc6ff);
    bottom: 8px;
    content: "";
    height: calc(80% - 50px);
    position: absolute;
    right: 0;
    width: 3px;
  }
}
@media screen and (max-width: 1300px) {
  .roadmap .partner-container {
    padding: 50px 30px 0;
  }
}
/////////////// Tokenomics-section  /////////


.par-bor {
  border: 2px solid #ff32a9;
  border-radius: 15px;
  padding: 10px 20px;
  transform: scale(1);

  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  &:hover {
    transform: scale(1.1);
  }
}
///////////////////////  Tokenomics-section

.Tokenomics-section {
  .bg-card {
    border-radius: 20px;
    text-align: center;
  }
}
.barcontainer {
  background: #FDD6F8;
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  margin-left: 50px;
  width: 50px;
  height: 320px;
  float: left;
  //border darken(#98AFC7, 40%) 3px solid
}
.bar-pra {
  font-family: Nunito;
  font-style: normal;  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #000000;
  transform: rotate(-90deg);
  width: 100%;
  position: absolute;
  bottom: 40px;
}

.bar {
  background-color: #ff32a9;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
  border-radius: 8px 8px 0px 0px;
  // border-top: 6px solid #FFF;
  box-sizing: border-box;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
}
// .borders{
//   border-bottom: 5px solid #ff32a9;
// }
@keyframes grow {
  from {
    transform: scaleY(0);
  }
}
.tokenomics__block {
	border-radius: 25px;
	border: 2px solid #FF32A9;
	padding: 40px 70px;
	color: #FF32A9;
	text-align: center;
	font-family: Nunito;
	max-width: 466px;
}
@media (max-width: 480px) {
	.tokenomics__block {
		padding: 30px;
	}
}
.tokenomics__10 {
	font-size: 62px;
	font-weight: 800;
	line-height: 1;
	margin: 0px 0px 20px 0px;
}
@media (max-width: 480px) {
	.tokenomics__10 {
		font-size: 40px;
	}
}

.tokenomics__lines {
	margin: 0px 0px 70px 0px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 0;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}
.tokenomics__lines p {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 0;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	color: #000;
	font-size: 20px;
	font-family: Nunito;
	font-style: normal;
	font-weight: 600;
	line-height: 140%;
}
@media (max-width: 820px) {
	.tokenomics__lines p {
		font-size: 16px;
	}
}
@media (max-width: 700px) {
	.tokenomics__lines p {
		margin: 0px 0px 10px 0px;
	}
	.tokenomics__lines p:last-child {
		margin: 0;
	}
}
.tokenomics__lines span {
	white-space: nowrap;
	margin: 0px 0px 0px 5px;
}
.tokenomics__lines ._small span {
	font-size: 16px;
}
@media (max-width: 1360px) {
	.tokenomics__lines ._small span {
		margin: 0;
	}
}
@media (max-width: 1024px) {
	.tokenomics__lines ._small span {
		font-size: 14px;
	}
}
@media (max-width: 360px) {
	.tokenomics__lines ._small span {
		font-size: 12px;
	}
}
@media (max-width: 1360px) {
	.tokenomics__lines ._small {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
	}
}
@media (max-width: 480px) {
	.tokenomics__lines {
		margin: 0px 0px 40px 0px;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		text-align: center;
	}
}

.tokenomics__text {
	font-size: 20px;
	color: #000;
	line-height: 1.2;
	margin: 0px 0px 50px 0px;
}
@media (max-width: 480px) {
	.tokenomics__text {
		font-size: 18px;
		margin: 0px 0px 30px 0px;
	}
}

.tokenomics__home {
	position: absolute;
	z-index: 2;
	bottom: -340px;
	left: 0;
	width: 600px;
}
.tokenomics__home img {
	width: 100%;
}
@media (max-width: 1920px) {
	.tokenomics__home {
		width: calc(100px + 500 * ((100vw - 320px) / 1600));
		bottom: calc(-150px + -150 * ((100vw - 320px) / 1600));
		left: calc(-300px + 300 * ((100vw - 320px) / 1600));
	}
}
@media (max-width: 1360px) {
	.tokenomics__home {
		display: none;
	}
}

/* ///////////////////////////////  Footer section  ////////////////////////*/


.follow {
	position: relative;
	padding: 162px 0px 0px 0px;
}
@media (max-width: 820px) {
	.follow {
		padding: 150px 0px 0px 0px;
	}
}
@media (max-width: 480px) {
	.follow {
		padding: 100px 0px 0px 0px;
	}
}

.follow__home {
	position: absolute;
	z-index: 1;
	right: 0;
	bottom: 0;
	width: 500px;
}
.follow__home img {
	width: 100%;
}
@media (max-width: 1360px) {
	.follow__home {
		width: calc(200px + 300 * ((100vw - 320px) / 1600));
	}
}

.follow__car {
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	    -ms-transform: translate(-50%, 0);
	        transform: translate(-50%, 0);
	width: 1130px;
}
.follow__car img {
	width: 100%;
}
@media (max-width: 1360px) {
	.follow__car {
		width: calc(300px + 830 * ((100vw - 320px) / 1600));
	}
}
@media (max-width: 480px) {
	.follow__car {
		left: 30%;
	}
}

.follow__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.follow__bg img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	   object-fit: cover;
	-o-object-position: top;
	   object-position: top;
}

.follow__content {
	position: relative;
	z-index: 2;
	padding: 0px 0px 360px 0px;
}
.follow__content ._container {
	position: relative;
}
@media (max-width: 1360px) {
	.follow__content {
		padding: 0px 0px 250px 0px;
	}
}
@media (max-width: 820px) {
	.follow__content {
		padding: 0px 0px 200px 0px;
	}
}

.follow__coins {
	position: absolute;
	top: 0;
	left: 0;
	width: 285px;
}
.follow__coins img {
	width: 100%;
}
@media (max-width: 1360px) {
	.follow__coins {
		width: calc(100px + 185 * ((100vw - 320px) / 1600));
	}
}
@media (max-width: 700px) {
	.follow__coins {
		display: none;
	}
}

.follow__title {
	margin: 0px 0px 40px 0px;
}

.follow__social {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 0;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	margin: 0px 0px 80px 0px;
}
.follow__social > *:not(:last-child) {
	margin: 0px 20px 0px 0px;
}
@media (max-width: 480px) {
	.follow__social > *:not(:last-child) {
		margin: 0px 15px 0px 0px;
	}
}
.follow__social a {
	width: 150px;
	height: 150px;
	background: url("../public/assets/btn_p.png") no-repeat;
	background-size: contain;
	position: relative;
	overflow: hidden;
  
}
.icon-tw::before {
  content: "";
	background: url("../public/assets/tw.svg") no-repeat;
	background-size: contain;
  width: 80px;
	height: 80px;
}
.icon-tg::before {
  content: "";
	background: url("../public/assets/tg.svg") no-repeat;
	background-size: contain;
  width: 80px;
	height: 80px;
}
.icon-eth::before {
  content: "";
	background: url("../public/assets/ether.png") no-repeat;
	background-size: contain;
  width: 80px;
	height: 80px;
}
.follow__social a::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url("../public/assets/btn_b.png") no-repeat;
	background-size: contain;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.follow__social a::before {
	position: absolute;
	z-index: 2;
	font-size: 40px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
@media (max-width: 1360px) {
	.follow__social a::before {
		font-size: calc(22px + 18 * ((100vw - 320px) / 1600));
	}
}
@media (max-width: 480px) {
	.follow__social a::before {
		top: 46%;
	}
}
@media (max-width: 360px) {
	.follow__social a::before {
		top: 40%;
	}
}
@media (min-width: 1024px) {
	.follow__social a:hover::before {
		color: #000;
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}
	.follow__social a:hover::after {
		opacity: 1;
		visibility: visible;
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}
}
@media (max-width: 1360px) {
	.follow__social a {
		width: calc(80px + 80 * ((100vw - 320px) / 1600));
		height: calc(80px + 80 * ((100vw - 320px) / 1600));
	}
}
.follow__social .icon-hz::before {
	font-size: 30px;
}
@media (max-width: 1360px) {
	.follow__social .icon-hz::before {
		font-size: calc(18px + 12 * ((100vw - 320px) / 1600));
	}
}
@media (max-width: 480px) {
	.follow__social {
		margin: 0px 0px 40px 0px;
	}
}

.follow__text {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 0;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	color: #000;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
@media (max-width: 480px) {
	.follow__text ._small {
		font-size: 14px;
	}
}
@media (max-width: 360px) {
	.follow__text ._small {
		font-size: 12px;
	}
}

@media (max-width: 1360px) {
	.follow__text {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		text-align: center;
		margin: 0 auto;
	}
}
@media (max-width: 480px) {
	.follow__text {
		font-size: 16px;
	}
}

///////////  MEDIA QUERRY //////////

@media only screen and (max-width: 600px) {
  .hero-section .content {
    h1 {
      font-size: 30px;
    }
    h5 {
      font-size: 19px;
    }
    .btn-sec {
      justify-content: center;
    }
  }
}
